import { Box, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import * as api from 'app/api';
import { getDiplomasByTemplateId } from 'app/api';
import ConfirmationDialog from 'app/components/ConfirmationDialog';
import Header from 'app/components/Header';
import TemplateDialog from 'app/components/TemplateDialog';
import BlocksPanel from 'app/editor/BlocksPanel';
import EditorViewport from 'app/editor/EditorViewport';
import TemplatePaper from 'app/editor/TemplatePaper';
import TemplateToolbar from 'app/editor/TemplateToolbar';
import { Routes } from 'app/router/Routes';
import { getDefaultBlockInput } from 'app/services/blocks';
import { AppDispatch, RootState } from 'app/store';
import { accessTokenSelector } from 'app/store/auth';
import { deleteTemplateDiplomasThunk } from 'app/store/diplomas';
import eventSlice, {
  fetchEventFieldsThunk,
  fetchEventThunk,
} from 'app/store/event';
import templateSlice, {
  createDiplomasTaskThunk,
  createTemplateBlockThunk,
  deleteTemplateThunk,
  fetchTemplateBlocksThunk,
  fetchTemplateThunk,
  saveThunk,
} from 'app/store/template';
import { compile } from 'path-to-regexp';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useUnmount } from 'react-use';

type Props = RouteComponentProps<{ id: string }>;

export default function TemplatePage({ match, history }: Props) {
  const id = match.params.id;
  const dispatch = useDispatch<AppDispatch>();
  const templateState = useSelector((state: RootState) => state.template);
  const eventState = useSelector((state: RootState) => state.event);
  const accessToken = useSelector(accessTokenSelector)!;
  const [isDelete, setDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAlreadyCreatingModal, setShowAlreadyCreatingModal] = useState(
    false
  );
  const [showVersionUpdateModal, setShowVersionUpdateModal] = useState(false);
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);
  const versionCheckIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
    null
  );
  const reminderTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isModalButtonsDisabled, setIsModalButtonsDisabled] = useState(false);
  const [lastTaskId, setLastTaskId] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const backPath = compile(Routes.EVENT)({
    id: String(templateState.data?.event_id),
  });

  const getCreatedDiplomas = async () => {
    try {
      const { data } = await getDiplomasByTemplateId(accessToken, id, {
        success: true,
      });
      return !!data?.length;
    } catch {
      return false;
    }
  };

  const getIsDiplomasCreating = async () => {
    try {
      const { data } = await api.getDiplomasTaskList(accessToken, {
        template_id: id,
        ordering: '-created_at',
        limit: 1,
      });
      const lastTask = data?.[0]?.id;
      if (!lastTask) return false;
      setLastTaskId(lastTask);
      const { data: progress } = await api.getDiplomasProgress(
        accessToken,
        lastTask
      );
      if (progress.Total && progress.Total > progress.Current) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const createDiplomas = async () => {
    await dispatch(
      createDiplomasTaskThunk({
        payload: {
          template_id: id,
        },
        access_token: accessToken,
      })
    );
  };

  const handleSnackClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  // Функция для отложенного напоминания
  const scheduleUpdateReminder = useCallback((delayMinutes: number = 0.2) => {
    // Очищаем предыдущий таймер, если он был
    if (reminderTimeoutRef.current) {
      clearTimeout(reminderTimeoutRef.current);
    }
    reminderTimeoutRef.current = setTimeout(() => {
      setShowVersionUpdateModal(true);
    }, delayMinutes * 60 * 1000);
  }, []);

  const fetchBuildVersion = useCallback(async () => {
    try {
      // Предполагаем, что у вас есть API-метод для получения версии сборки
      const { data } = await api.getBuildVersion();

      if (data) {
        // Если это первая загрузка, просто сохраняем версию
        if (currentVersion === null) {
          setCurrentVersion(data);
        }
        // Если версия изменилась, показываем модальное окно
        else if (currentVersion !== data) {
          setShowVersionUpdateModal(true);
          // Останавливаем интервал, чтобы не спамить пользователя
          if (versionCheckIntervalRef.current) {
            clearInterval(versionCheckIntervalRef.current);
            versionCheckIntervalRef.current = null;
          }
        }
      }
    } catch (error) {
      console.error('Ошибка при получении версии сборки:', error);
    }
  }, [currentVersion]);

  useEffect(() => {
    dispatch(fetchTemplateThunk({ id, access_token: accessToken }))
      .then(unwrapResult)
      .then((template) => {
        dispatch(
          fetchTemplateBlocksThunk({
            id: template.id,
            access_token: accessToken,
          })
        );
        dispatch(
          fetchEventThunk({ access_token: accessToken, id: template?.event_id })
        );
        dispatch(
          fetchEventFieldsThunk({
            id: Number(template?.event_id),
            access_token: accessToken,
          })
        ).then(() => {
          dispatch(templateSlice.actions.setPageReady(true));
        });
      });
  }, [accessToken, dispatch, id]);

  useEffect(() => {
    if (templateState.task?.id) {
      history.push({
        pathname: `/templates/${id}/create_diplomas/${templateState.task?.id}`,
      });
    }
  }, [history, id, templateState.task?.id]);

  // Запускаем интервал проверки версии при монтировании компонента
  useEffect(() => {
    fetchBuildVersion();
    // Устанавливаем интервал проверки каждые 15 минут (900000 мс)
    versionCheckIntervalRef.current = setInterval(fetchBuildVersion, 900000);
    // Очищаем интервал при размонтировании компонента
    return () => {
      if (versionCheckIntervalRef.current) {
        clearInterval(versionCheckIntervalRef.current);
        versionCheckIntervalRef.current = null;
      }
    };
  }, [fetchBuildVersion]);

  const handleReloadPage = () => {
    window.location.reload();
  };
  //Таймер напоминания если пользователь решил не перезагружать
  useUnmount(() => {
    dispatch(templateSlice.actions.reset());
    dispatch(eventSlice.actions.reset());
    // Очищаем интервал при размонтировании компонента
    if (versionCheckIntervalRef.current) {
      clearInterval(versionCheckIntervalRef.current);
      versionCheckIntervalRef.current = null;
    }
    // Очищаем таймер напоминания
    if (reminderTimeoutRef.current) {
      clearTimeout(reminderTimeoutRef.current);
      reminderTimeoutRef.current = null;
    }
  });

  return (
    <Box height="100vh" display="flex" flexDirection="column" minWidth="1280px">
      <Header position="static" />
      {templateState.data && (
        <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
          <TemplateToolbar
            loading={templateState.loading}
            backPath={backPath}
            template={templateState.data}
            onSave={() => {
              dispatch(saveThunk(accessToken));
            }}
            onEdit={() => setEdit(true)}
            onDelete={() => setDelete(true)}
            onDiplomas={async () => {
              const isDiplomasCreating = await getIsDiplomasCreating();
              if (isDiplomasCreating) {
                setShowAlreadyCreatingModal(true);
                return;
              }

              const isCreatedDiplomas = await getCreatedDiplomas();
              if (isCreatedDiplomas) {
                setShowDeleteModal(true);
                return;
              }
              createDiplomas();
            }}
          />
          <Box flex={1} overflow="inherit" display="flex">
            <Box clone flexShrink={0} overflow="auto" flexBasis="320px">
              <BlocksPanel
                blocks={templateState.blocks}
                selectedBlockId={templateState.selectedBlock}
                onSelectBlock={(id) =>
                  dispatch(templateSlice.actions.selectBlock(id))
                }
                onDeleteBlock={(id) =>
                  dispatch(templateSlice.actions.deleteBlock(id))
                }
                onChangeBlock={(block) =>
                  dispatch(templateSlice.actions.updateBlock(block))
                }
                onCreateBlock={(type) =>
                  dispatch(createTemplateBlockThunk(getDefaultBlockInput(type)))
                    .then(unwrapResult)
                    .then((block) =>
                      dispatch(templateSlice.actions.selectBlock(block.id))
                    )
                }
              />
            </Box>
            <Box flex="1" position="relative" overflow="auto">
              <Box
                id="rich-text-toolbar-portal"
                position="sticky"
                left="0"
                right="0"
                top="0"
                zIndex="999"
              />
              <EditorViewport>
                <TemplatePaper
                  template={templateState.data}
                  blocks={templateState.blocks}
                  selectedBlock={templateState.selectedBlock}
                  onChangeBlock={(block) =>
                    dispatch(templateSlice.actions.updateBlock(block))
                  }
                  onSelectBlock={(id) =>
                    dispatch(templateSlice.actions.selectBlock(id))
                  }
                />
              </EditorViewport>
            </Box>
          </Box>
          <TemplateDialog
            title="Настройки шаблона"
            open={isEdit && !!eventState?.data}
            onClose={() => setEdit(false)}
            enableReinitialize
            initialValues={templateState.data}
            achievementRoles={eventState.data?.achievement_roles}
            onSubmit={(input) => {
              dispatch(templateSlice.actions.updateTemplate(input));
              setEdit(false);
            }}
          />
          <ConfirmationDialog
            onClose={() => setDelete(false)}
            title="Удалить шаблон"
            text="Вы действительно хотите удалить шаблон?"
            open={isDelete}
            onSubmit={() =>
              dispatch(
                deleteTemplateThunk({
                  templateId: id,
                  access_token: accessToken,
                })
              ).then(() => {
                history.push(backPath);
              })
            }
          />
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        open={snackbarOpen}
        onClose={handleSnackClose}
        message="I love snacks"
        key={'topcenter'}
      >
        <Alert severity="error" variant="filled">
          Не удалось удалить дипломы
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        onClose={() => {
          setShowDeleteModal(false);
          setIsModalButtonsDisabled(true);
          createDiplomas().then(() => setIsModalButtonsDisabled(false));
        }}
        title="Ранее созданные дипломы"
        text="Обнаружены дипломы, связанные с текущим шаблоном. Хотите их удалить перед созданием новых?"
        submitText="Удалить"
        cancelText="Оставить"
        open={showDeleteModal}
        disabled={isModalButtonsDisabled}
        cancelDisabled={isModalButtonsDisabled}
        onSubmit={() => {
          setIsModalButtonsDisabled(true);
          dispatch(
            deleteTemplateDiplomasThunk({
              templateId: id,
              access_token: accessToken,
            })
          )
            .then((res) => {
              if (res?.type === 'diplomas/deleteTemplateDiplomas/rejected') {
                setSnackbarOpen(true);
              }
              createDiplomas();
            })
            .then(() => setIsModalButtonsDisabled(false));
        }}
      />
      <ConfirmationDialog
        onClose={() => {
          setShowAlreadyCreatingModal(false);
          history.push(
            compile(Routes.CREATE_DIPLOMAS)({
              templateId: id,
              id: lastTaskId,
            })
          );
        }}
        disableBackdropClick
        disableEscapeKeyDown
        title="Уже имеется запущенная задача"
        text="Сейчас выполняется процесс создания дипломов. Вы можете подождать его завершения или запустить новую задачу, если уверены в необходимости, но это может привести к параллельному выполнению процессов."
        submitText="Создать новую задачу"
        cancelText="Перейти к текущей задаче"
        open={showAlreadyCreatingModal}
        disabled={isModalButtonsDisabled}
        cancelDisabled={isModalButtonsDisabled}
        onSubmit={() => {
          createDiplomas().then(() => setShowAlreadyCreatingModal(false));
        }}
      />
      <ConfirmationDialog
        onClose={() => {
          setShowVersionUpdateModal(false);
          scheduleUpdateReminder();
        }}
        disableBackdropClick
        disableEscapeKeyDown
        title="Доступна новая версия приложения"
        text="Приложение было обновлено. Пожалуйста, перезагрузите страницу, чтобы использовать новую версию."
        submitText="Перезагрузить сейчас"
        cancelText="Позже"
        open={showVersionUpdateModal}
        onSubmit={handleReloadPage}
      />
    </Box>
  );
}
