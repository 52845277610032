import { conditionalFields, fields, fonts } from 'app/constants/editor';
import { RenderState } from 'app/store/render';
import get from 'lodash/get';
import { ComponentProps, useMemo } from 'react';
import ReactDOM from 'react-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';

import { DiplomaErrorType } from '../api/types';

import RichTextToolbar from './RichTextToolbar';

const REGEX = /(<)(script[^>]*>[^<]*(?:<(?!\/script>)[^<]*)*<\/script>|\/?\b[^<>]+>|!(?:--\s*(?:(?:\[if\s*!IE]>\s*-->)?[^-]*(?:-(?!->)-*[^-]*)*)--|\[CDATA[^\]]*(?:](?!]>)[^\]]*)*]])>)|(e)/gi;

type Props = {
  value?: string;
  onChange?: (value: Props['value']) => any;
  editorRef?: (editor: ReactQuill | null) => any;
  onError?: (message: string) => any;
  renderState?: RenderState;
  showToolbar?: boolean;
  id: string;
} & Omit<ComponentProps<typeof StyledReactQuill>, 'value' | 'onChange'>;

const Parchment = Quill.import('parchment');

export const fieldValues = [false, ...fields];
export const Field = new Parchment.Attributor.Attribute('field', 'data-field', {
  scope: Parchment.Scope.INLINE,
});

Quill.register('formats/field', Field);
Quill.register('attributors/attribute/field', Field);

export const lineHeightValues = [
  false,
  '1.0',
  '1.2',
  '1.5',
  '1.8',
  '2.0',
  '2.4',
  '2.8',
  '3.0',
];
export const LineHeightClass = new Parchment.Attributor.Class(
  'lineheight',
  'ql-line-height',
  {
    scope: Parchment.Scope.INLINE,
  }
);
export const LineHeightStyle = new Parchment.Attributor.Style(
  'lineheight',
  'line-height',
  {
    scope: Parchment.Scope.INLINE,
  }
);
Parchment.register(LineHeightClass);
Parchment.register(LineHeightStyle);

export const TextTransformClass = new Parchment.Attributor.Class(
  'texttransform',
  'ql-text-transform',
  {
    scope: Parchment.Scope.INLINE,
  }
);
export const TextTransformStyle = new Parchment.Attributor.Style(
  'texttransform',
  'text-transform',
  {
    scope: Parchment.Scope.INLINE,
  }
);
Parchment.register(TextTransformClass);
Parchment.register(TextTransformStyle);

export const letterSpacingValues = [
  false,
  '1.0mm',
  '1.2mm',
  '1.5mm',
  '1.8mm',
  '2.0mm',
  '2.4mm',
  '2.8mm',
  '3.0mm',
];
export const LetterSpacingClass = new Parchment.Attributor.Class(
  'letterspacing',
  'ql-letter-spacing',
  {
    scope: Parchment.Scope.INLINE,
  }
);
export const LetterSpacingStyle = new Parchment.Attributor.Style(
  'letterspacing',
  'letter-spacing',
  {
    scope: Parchment.Scope.INLINE,
  }
);
Parchment.register(LetterSpacingClass);
Parchment.register(LetterSpacingStyle);

export const Size = Quill.import('attributors/style/size');
Size.whitelist = undefined;
export const sizeValues = [
  false,
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '42px',
  '54px',
  '68px',
  '84px',
  '98px',
];
Quill.register(Size, true);

export const Font = Quill.import('formats/font');
Font.whitelist = undefined;
export const fontValues = [false, 'monospace', ...Object.keys(fonts)];

export const FontsImport = () => (
  <style
    dangerouslySetInnerHTML={{
      __html: Object.values(fonts)
        .map(({ url }) => url && `@import url('${url}');`)
        .filter(Boolean)
        .join('\n'),
    }}
  />
);

Quill.register(Font, true);

const createCustomFormatHandler = (
  name: string,
  placeholder: string = 'Введите значение'
) => {
  return function (this: { quill: Quill }, value: any) {
    if (value === 'custom') {
      value = prompt(placeholder);
    }
    this.quill.format(name, value);
  };
};

export default function RichText({
  value,
  onChange,
  editorRef,
  renderState,
  onError,
  showToolbar,
  id,
  block,
  ...restProps
}: Props) {
  const toolbarId = `toolbar_${id}`;
  const modules = useMemo(
    () => ({
      toolbar: renderState
        ? false
        : {
            container: `#${toolbarId}`,
            handlers: {
              color: createCustomFormatHandler(
                'color',
                'Введите цвет в HEX формате'
              ),
              background: createCustomFormatHandler(
                'background',
                'Введите цвет в HEX формате'
              ),
              'line-height': createCustomFormatHandler('lineheight'),
              'letter-spacing': createCustomFormatHandler('letterspacing'),
              size: createCustomFormatHandler('size'),
            },
          },
    }),
    [renderState, toolbarId]
  );
  const getUserFullName = (user: any): string => {
    if (!user) return '';
    const firstName = user?.first_name;
    const middleName = user?.middle_name;
    const lastName = user?.last_name;
    const userNames = [];
    if (firstName) {
      userNames.push(firstName);
    }
    if (middleName) {
      userNames.push(middleName);
    }
    if (lastName) {
      userNames.push(lastName);
    }
    return userNames.join(' ');
  };

  const makePrerenderContainerStyles = (
    htmlElement: HTMLElement,
    userContainer: HTMLElement,
    block: { content: string }
  ) => {
    const isBold = !!block?.content?.match(/<strong/g);
    const isMono = !!block?.content?.match(/<code/g);
    const isItalic = !!block?.content?.match(/<em/g);
    const isUnderline = !!block?.content?.match(/<u/g);
    const fontFamily = block?.content?.match(/class="ql-font-(\w+)/g);
    const fontFamilyValue = fontFamily
      ? fontFamily[0].replace('class="ql-font-', '')
      : '';
    const textTransform = htmlElement.style.textTransform;
    const letterSpacing = htmlElement.style.letterSpacing;
    const lineHeight = htmlElement.style.lineHeight;

    if (isBold) {
      userContainer.style.fontWeight = 'bold';
    }
    if (isMono) {
      userContainer.style.fontFamily = 'monospace';
    }
    if (isItalic) {
      userContainer.style.fontStyle = 'italic';
    }
    if (isUnderline) {
      userContainer.style.textDecoration = 'underline';
    }
    if (textTransform) {
      userContainer.style.textTransform = textTransform;
    }
    if (letterSpacing) {
      userContainer.style.letterSpacing = letterSpacing;
    }
    if (lineHeight) {
      userContainer.style.lineHeight = lineHeight;
    }
    if (fontFamilyValue) {
      if (fontFamilyValue === 'monospace') {
        userContainer.style.fontFamily = fontFamilyValue;
      } else {
        const fontName = Object.values(fonts).find((item) => {
          return item.styleName === fontFamilyValue;
        });
        if (fontName) {
          userContainer.style.fontFamily = fontName.styleName;
        }
      }
    }

    userContainer.style.display = 'inline-block';
    userContainer.style.width = 'max-content';
  };

  const formattedValue = useMemo(() => {
    if (!renderState) return value;
    const makeTeamUsersString = (teamUsers: any[]): string => {
      if (teamUsers.length <= 7) {
        return teamUsers
          .map(
            (user: any) =>
              `<span style='white-space: nowrap;'>${getUserFullName(
                user
              )}<br/></span>` || ''
          )
          .join('');
      }
      return teamUsers
        .map(
          (user: any) =>
            `<span style='white-space: nowrap;'>${getUserFullName(
              user
            )}</span>` || ''
        )
        .join(', ');
    };

    const root = document.createElement('div');
    root.innerHTML = value as any;

    if (
      block?.type === 'conditionalText' &&
      block?.payload?.conditional_field
    ) {
      const condition = get(renderState, block.payload.conditional_field);
      if (condition) {
        const altConditionValue =
          conditionalFields[
            block.payload.conditional_field as keyof typeof conditionalFields
          ][1].value;

        let newValue = value;
        if (condition === altConditionValue) {
          const altContent = block?.payload?.alt_content || '';
          const isText = altContent.replace(REGEX, '');
          if (isText) {
            newValue = block?.payload?.alt_content;
          }
        }
        root.innerHTML = newValue as any;
      }
    }

    root.querySelectorAll('*[data-field]').forEach((el) => {
      const key = el.getAttribute('data-field');
      if (key) {
        let value: any;

        const rawValue = get(renderState, key);
        if (Array.isArray(rawValue)) {
          if (key.includes('checkbox')) {
            value = rawValue.join('<br/>');
          } else {
            value = key === 'team.users' ? rawValue : `${rawValue.join(', ')} `;
          }
        } else {
          const val = get(renderState, key);
          value = `${val ?? ''} `;
        }

        let innerHtmlValue = '';

        if (key === 'team.users' && Array.isArray(value)) {
          value = makeTeamUsersString(value);

          const body = document.querySelector('#body');
          const usersContainerDiv = document.createElement('div');
          usersContainerDiv.classList.add('inline');

          body?.append(usersContainerDiv);
          const userContainer = body?.querySelector('.inline') as HTMLElement;
          userContainer.innerHTML = value;

          const htmlElement = el as HTMLElement;

          makePrerenderContainerStyles(
            htmlElement,
            userContainer,
            block?.payload
          );
          const fontSize = htmlElement.style.fontSize;
          const isOl = !!block?.payload?.content?.match(/<ol/g);
          const isUl = !!block?.payload?.content?.match(/<ul/g);
          let fontSizeValue = fontSize ? parseInt(fontSize) : 12;

          userContainer.style.fontSize = fontSizeValue + 'px';

          let userContainerWidth = userContainer?.offsetWidth;
          let userContainerHeight = userContainer?.offsetHeight;

          // !берем небольшой запас на различные свойства контейнера
          let blockWidth =
            block?.payload?.width - 10 >= 0 ? block?.payload?.width - 10 : 0;
          const blockHeight =
            block?.payload?.height - 10 >= 0 ? block?.payload?.height - 10 : 0;

          if (isOl || isUl) {
            // !увеличиваем отступ чтобы влез список
            blockWidth = blockWidth - 70 >= 0 ? blockWidth - 70 : 0;
          }

          const calculateFontSize = (fontSize = fontSizeValue) => {
            if (
              (userContainerWidth >= blockWidth ||
                userContainerHeight >= blockHeight) &&
              fontSize > 8
            ) {
              fontSize = fontSize - 1;
              userContainer.style.fontSize = fontSize + 'px';
              userContainerWidth = userContainer?.offsetWidth;
              userContainerHeight = userContainer?.offsetHeight;
              calculateFontSize(fontSize);
            } else {
              if (
                userContainerWidth >= blockWidth ||
                userContainerHeight >= blockHeight
              ) {
                const stringifyError = JSON.stringify({
                  type: DiplomaErrorType.OVERFLOW,
                  field: key,
                  value,
                });
                onError?.(stringifyError);
              }

              innerHtmlValue = `<span style='font-size: ${fontSize}px'>${value}</span>`;
              body?.removeChild(userContainer);
            }
          };
          calculateFontSize();
        }
        if (!value) {
          const stringifyError = JSON.stringify({
            type: DiplomaErrorType.MISSING_FIELD,
            field: key,
            value,
          });
          onError?.(stringifyError);
        }
        if (key === 'team.users') {
          el.innerHTML = innerHtmlValue || '';
        } else if (key.includes('checkbox')) {
          el.innerHTML = value === 'undefined ' ? '' : value;
          // el.innerHTML = value + 'dddd';
        } else {
          el.textContent = value;
        }
      }
    });
    return root.innerHTML;
  }, [renderState, value, onError, block?.payload, block?.type]);
  return (
    <>
      {!renderState &&
        ReactDOM.createPortal(
          <RichTextToolbar
            style={{ display: showToolbar ? undefined : 'none' }}
            id={toolbarId}
          />,
          document.getElementById('rich-text-toolbar-portal')!
        )}
      <StyledReactQuill
        id={id}
        $isRender={!!renderState}
        value={formattedValue}
        ref={editorRef}
        onChange={onChange}
        modules={modules}
        {...(restProps as any)}
      />
    </>
  );
}

const StyledReactQuill = styled(ReactQuill).attrs(({ theme }) => ({
  theme: 'snow',
  styledTheme: theme,
}))<{ $isRender?: boolean }>`
  height: 100%;

  .ql-container.ql-container {
    border: none;
  }

  .ql-editor {
    padding: 0;
    overflow: visible;
  }

  ${Object.entries(fonts).map(
    ([key, font]) => css`
      .ql-font-${key} {
        font-family: '${font.name}';
      }
    `
  )}

  ${(props) =>
    !props.$isRender &&
    css`
      .ql-editor *[data-field] {
        /* Название поля над текстом. */
        position: relative;

        &:after {
          content: '</>';
          position: absolute;
          pointer-events: none;
          left: 50%;
          top: -3px;
          transform: translate(-50%, -50%);
          color: ${props.styledTheme.palette.primary.main};
          text-shadow: 0 0 0.5px #333;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-family: Roboto;
          pointer-events: none;
        }

        &:hover:after {
          content: attr(data-field);
        }
      }
    `}
`;
