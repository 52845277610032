import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { ComponentProps, ReactNode } from 'react';

type Props = Omit<ComponentProps<typeof Dialog>, 'onSubmit'> & {
  title?: ReactNode;
  text?: ReactNode;
  submitText?: ReactNode;
  cancelText?: ReactNode;
  disabled?: boolean;
  cancelDisabled?: boolean;
  noSubmit?: boolean;
  onSubmit?: () => any;
};

export default function ConfirmationDialog({
  title,
  text,
  disabled,
  cancelDisabled,
  submitText = 'Подтвердить',
  cancelText = 'Отменить',
  noSubmit = false,
  onSubmit,
  ...restProps
}: Props) {
  return (
    <Dialog {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={restProps.onClose as any} disabled={cancelDisabled}>
          {cancelText}
        </Button>
        {!noSubmit && (
          <Button
            onClick={onSubmit}
            disabled={disabled}
            type="submit"
            color="primary"
          >
            {submitText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
