export type Error = {
  message: string;
};

export type UserToken = {
  access_token: string;
  refresh_token: string;
};

export type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  avatar?: string;
};

export enum TemplateFormat {
  A4 = 'A4',
  A5 = 'A5',
}

export enum TemplateOrientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export type ForTeamsDiploma = true | false;

export type Role = {
  role: string;
};

export type Template = {
  id: string;
  title: string;
  format: TemplateFormat;
  orientation: TemplateOrientation;
  event_id: Event['id'];
  created_at: string;
  role: string;
  for_teams: boolean;
};

export type EventFields = {
  created_at: string;
  help_text: string;
  id: number;
  name: string;
  options: string;
  order: number;
  required: boolean;
  type: string;
  updated_at: string;
};

export type TemplateInput = {
  title: string;
  format: TemplateFormat;
  orientation: TemplateOrientation;
  role: string;
  for_teams: boolean;
};

export type TemplateBlock<T extends string, P extends Record<string, any>> = {
  id: string;
  type: T;
  payload?: Partial<P>;
  template_id: string;
};

export type TemplateBlockInput<TBlock extends TemplateBlock<any, any>> = {
  type: TBlock['type'];
  payload: TBlock['payload'];
};

export type Limits = {
  id: number;
  auto_approve?: boolean;
  collect_snils?: boolean;
  collect_phones?: boolean;
  participant_limit?: number;
  date_end_request?: string;
  date_end_update?: string;
  user_age_start?: number;
  user_age_end?: number;
  requires_project?: boolean;
  requires_stepik?: boolean;
  requires_github?: boolean;
  requires_kaggle?: boolean;
  requires_leader?: boolean;
  requires_vk?: boolean;
  requires_telegram?: boolean;
  countries?: string[];
  regions?: string[];
  cities?: string[];
  description?: string;
  event: number;
};

export type Organization = {
  id: number;
  country: string;
  federal_district: string;
  region_with_type: string;
  city: string;
  settlement: string;
  city_district_with_type: string;
  address?: string;
  city_type_full: string;
  settlement_type_full: string;
  geo_lat: string;
  geo_lon: string;
  subsidiary_count: number;
  subsidiary_organizations: number[];
  representatives: number[];
  created_at: string;
  updated_at: string;
  logo?: string;
  description?: string;
  full_name?: string;
  short_name?: string;
  is_permitted: boolean;
  contact_email?: string;
  contact_phone?: string;
  contact_first_name?: string;
  contact_last_name?: string;
  contact_middle_name?: string;
  is_representative: boolean;
  districts: string[];
  regions: string[];
  cities: string[];
  educational_category: '' | 'high' | 'secondary';
  geo_data: number | null;
  person: number | null;
  legal_entity: number | null;
  parent_organization: number | null;
  admin_users: number[];
  has_actual_agreement?: boolean;
  has_approved_legal_entity?: boolean;
};

export type AchievementRole = {
  id: number;
  is_diplomas_issued: boolean;
  name: string;
};

export type Event = {
  id: number;
  country: string;
  federal_district: string;
  region_with_type: string;
  city: string[];
  settlement: string;
  city_district_with_type: string;
  address: string;
  city_type_full: string;
  settlement_type_full: string;
  geo_lat: string;
  geo_lon: string;
  limits: Limits;
  organization: Organization;
  achievement_roles: AchievementRole[];
  is_favorite: boolean;
  created_at: string;
  updated_at: string;
  title: string;
  alt_title?: string;
  description?: string;
  cover?: string;
  background?: string;
  url?: string;
  format?: 'online' | 'offline';
  teams_involved?: 0 | 1 | 2;
  mentors_allowed?: boolean;
  start?: string | null;
  end?: string | null;
  time_matters?: boolean;
  expected_start?: string | null;
  imported_at: string;
  public: boolean;
  draft?: boolean;
  hidden?: boolean;
  canceled?: boolean;
  cancellation_reason?: string;
  achievements_allowed?: boolean;
  requests_allowed?: boolean;
  support_i18n?: boolean;
  simplified_registration?: boolean;
  self_check_in?: boolean;
  promoted?: boolean;
  venue_id?: number | null;
  questionnaire_completed_message?: string;
  notification_email_message?: string;
  deferred_notification_message?: string;
  deferred_notification_timeout?: number | null;
  max_users?: number | null;
  owner_can_moderate?: boolean;
  user_in_multiple_teams?: boolean;
  team_assignment_method?:
    | 'completion'
    | 'completion_soft'
    | 'single'
    | 'single_soft'
    | '';
  team_create_notification: boolean;
  is_diplomas_issued?: 'all' | 'roles' | '';
  diplomas_dark_theme?: boolean;
  achievement_roles_count: number;
  talent_roles_count: number;
  achievements_count: number;
  requests_count: number;
  gir_collection?: boolean | null;
  geo_data: number | null;
  type?: number | null;
  imported_by?: number | null;
  created_by?: number | null;
  meta_event?: number | null;
  brands?: number[];
  routes?: number[];
};

export type EventsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Event[];
};

export type OrganizationsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Organization[];
};

export enum TaskStatus {
  CREATED,
  PENDING,
  COMPLETED,
  ERROR,
  FATAL,
}

export type DiplomasTaskPayload = {
  template_id: string;
  only_achievements?: number[];
};

export type EventRequestParams = {
  limit: number;
  offset: number;
  search?: string;
  ordering: '-start, -updated_at';
};

export type DiplomasTaskParams = {
  template_id: string;
  ordering?: string;
  limit?: number;
  offset?: number;
};

export type DiplomasTaskMeta = {
  total: number;
  current: number;
};

export type DiplomasTask = {
  id: string;
  status: TaskStatus;
  payload?: DiplomasTaskPayload;
  meta?: DiplomasTaskMeta;
};

export type DiplomasProgress = {
  Total: number;
  Current: number;
};

export type Diploma = {
  id: string;
  template_id: string;
  achievement_id: number;
  task_id: string;
  user_id?: number;
  team_id?: number;
  pdf: string;
  created_at: string;
  error?: {
    field: string;
    type: DiplomaErrorType;
    value?: string;
  };
};

export type Achievement = {
  id: number;
};

export type CloneTemplateInput = {
  event_id: number;
};

export type DiplomasByTempateIdParams = {
  achievement_id?: string;
  user_id?: string;
  success?: boolean;
};

export enum DiplomaErrorType {
  OVERFLOW = 'overflow',
  MISSING_FIELD = 'missing_field',
}

export interface IRefreshData {
  template_id: string;
  only_achievements: number[];
  diplomaId: Diploma['id'];
}
export interface IDeleteAndCreateData {
  idsToDelete: string[];
  achForCreate: number[];
}
