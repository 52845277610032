/* eslint-disable import/order */
import 'focus-visible';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Chevin';
    font-weight: normal;
    src: url(${require('../fonts/Chevin/Chevin_Light.ttf').default});
  }

  @font-face {
    font-family: 'Chevin';
    font-weight: 500;
    src: url(${require('../fonts/Chevin/Chevin_Medium.ttf').default});
  }

  @font-face {
    font-family: 'Chevin';
    font-weight: 700;
    src: url(${require('../fonts/Chevin/Chevin_Bold.ttf').default});
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  .appLink {
    color: #0f888f ;
    text-decoration: none;
  }
  .appLink:hover {
    text-decoration: underline;
  }
`;

export default GlobalStyle;
