import axios from 'axios';

import * as local from './local';
import * as types from './types';

export { local, types };

export const $axios = axios.create();

export function getAuthHeaders(access_token: string) {
  return {
    Authorization: `Bearer ${access_token}`,
  };
}

export function getLoginUrl() {
  return `/api/auth/redirect`;
}

export function createUserToken(code: string) {
  return $axios.get<types.UserToken>('/api/auth/complete', {
    params: {
      code,
    },
    skipAuthRefresh: true,
  } as any);
}

export function refreshUserToken(refresh_token: string) {
  return $axios.post<types.UserToken>('/api/auth/refresh', refresh_token, {
    skipAuthRefresh: true,
  } as any);
}

export function getUser(access_token: string) {
  return $axios.get<types.User>('/api/user', {
    headers: getAuthHeaders(access_token),
  });
}

export function getUsersOrganizations(
  access_token: string,
  user_id: types.User['id']
) {
  return $axios.get<types.OrganizationsResponse>(
    `/talent_api/users/${user_id}/organizations/`,
    {
      headers: getAuthHeaders(access_token),
      params: {
        limit: 100,
      },
    }
  );
}

export function getOrganization(
  access_token: string,
  orgId: types.Organization['id']
) {
  return $axios.get<types.Organization>(`/talent_api/organizations/${orgId}/`, {
    headers: getAuthHeaders(access_token),
    params: {
      include_fields: 'has_actual_agreement,has_approved_legal_entity',
    },
  });
}

export function getEvents(
  access_token: string,
  params: types.EventRequestParams
) {
  return $axios.get<types.EventsResponse>('/talent_api/events/', {
    headers: getAuthHeaders(access_token),
    params,
  });
}

export function getEvent(access_token: string, id: types.Event['id']) {
  return $axios.get<types.Event>(`/api/events/${id}`, {
    headers: getAuthHeaders(access_token),
  });
}

export function getEventFields(access_token: string, id: types.Event['id']) {
  return $axios.get<types.EventFields[]>(`/api/events/${id}/fields`, {
    headers: getAuthHeaders(access_token),
  });
}

export function getEventTemplates(access_token: string, id: types.Event['id']) {
  return $axios.get<types.Template[]>(`/api/events/${id}/templates`, {
    headers: getAuthHeaders(access_token),
  });
}

export function createEventTemplate(
  access_token: string,
  eventId: types.Event['id'],
  input: types.TemplateInput
) {
  return $axios.post<types.Template>(
    `/api/events/${eventId}/templates`,
    input,
    {
      headers: getAuthHeaders(access_token),
    }
  );
}

export function deleteTemplate(
  access_token: string,
  templateId: types.Template['id']
) {
  return $axios.delete<void>(`/api/templates/${templateId}`, {
    headers: getAuthHeaders(access_token),
  });
}

export function getTemplate(
  access_token: string,
  templateId: types.Template['id']
) {
  return $axios.get<types.Template>(`/api/templates/${templateId}`, {
    headers: getAuthHeaders(access_token),
  });
}

export function updateTemplate(
  access_token: string,
  templateId: types.Template['id'],
  input: types.TemplateInput
) {
  return $axios.put<types.Template>(`/api/templates/${templateId}`, input, {
    headers: getAuthHeaders(access_token),
  });
}

export function getTemplateBlocks(
  access_token: string,
  templateId: types.Template['id']
) {
  return $axios.get<local.TemplateBlock[]>(
    `/api/templates/${templateId}/blocks`,
    {
      headers: getAuthHeaders(access_token),
    }
  );
}

export function deleteTemplateBlock(
  access_token: string,
  id: local.TemplateBlock['id']
) {
  return $axios.delete(`/api/templates/blocks/${id}`, {
    headers: getAuthHeaders(access_token),
  });
}

export function updateTemplateBlock(
  access_token: string,
  block: local.TemplateBlock
) {
  return $axios.put<local.TemplateBlock>(
    `/api/templates/blocks/${block.id}`,
    block,
    {
      headers: getAuthHeaders(access_token),
    }
  );
}

export function createTemplateBlock(
  access_token: string,
  input: local.TemplateBlockInput,
  templateId: types.Template['id']
) {
  return $axios.post<local.TemplateBlock>(
    `/api/templates/${templateId}/blocks`,
    input,
    {
      headers: getAuthHeaders(access_token),
    }
  );
}

export function createDiplomasTask(
  access_token: string,
  payload: types.DiplomasTaskPayload
) {
  return $axios.post<types.DiplomasTask>(`/api/diplomas/tasks`, payload, {
    headers: getAuthHeaders(access_token),
  });
}

export function getDiplomasTaskList(
  access_token: string,
  params: types.DiplomasTaskParams
) {
  return $axios.get<types.DiplomasTask[]>(`/api/diplomas/tasks`, {
    headers: getAuthHeaders(access_token),
    params,
  });
}

export function getDiplomasTask(
  access_token: string,
  id: types.DiplomasTask['id']
) {
  return $axios.get<types.DiplomasTask>(`/api/diplomas/tasks/${id}`, {
    headers: getAuthHeaders(access_token),
  });
}

export function getDiplomasProgress(
  access_token: string,
  id: types.DiplomasTask['id']
) {
  return $axios.get<types.DiplomasProgress>(
    `/api/diplomas/tasks/${id}/progress`,
    {
      headers: getAuthHeaders(access_token),
    }
  );
}

export function getDiplomasByTemplateId(
  access_token: string,
  templateId: types.Template['id'],
  params: types.DiplomasByTempateIdParams
) {
  return $axios.get<types.Diploma[]>(`/api/templates/${templateId}/diplomas`, {
    headers: getAuthHeaders(access_token),
    params,
  });
}

export function deleteDiplomasByTemplateId(
  access_token: string,
  templateId: types.Template['id']
) {
  return $axios.delete(`/api/templates/${templateId}/diplomas`, {
    headers: getAuthHeaders(access_token),
  });
}

export function deleteDiplomaById(
  access_token: string,
  diplomaId: types.Diploma['id']
) {
  return $axios.delete(`/api/diplomas/${diplomaId}`, {
    headers: getAuthHeaders(access_token),
  });
}

export function cloneTemplate(
  access_token: string,
  templateId: types.Template['id'],
  input: types.CloneTemplateInput
) {
  return $axios.post<types.Template>(
    `/api/templates/${templateId}/clone`,
    input,
    {
      headers: getAuthHeaders(access_token),
    }
  );
}

export const getBuildVersion = () => {
  return $axios.get<string>('/api/build_version');
};
