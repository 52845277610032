import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  ArrowBack,
  ArrowForward,
  Delete,
  Save,
  Settings,
} from '@material-ui/icons';
import * as api from 'app/api';
import ConfirmationDialog from 'app/components/ConfirmationDialog';
import { Routes } from 'app/router/Routes';
import { RootState } from 'app/store';
import { accessTokenSelector } from 'app/store/auth';
import { compile } from 'path-to-regexp';
import { ComponentProps, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

type Props = {
  backPath: string;
  template: api.types.Template;
  onEdit?: () => any;
  onDelete?: () => any;
  onSave?: () => any;
  loading?: boolean;
  onDiplomas?: () => any;
} & ComponentProps<typeof AppBar>;

export default function TemplateToolbar({
  backPath,
  template,
  onEdit,
  onDelete,
  onDiplomas,
  onSave,
  loading,
  ...restProps
}: Props) {
  const { data: event } = useSelector((state: RootState) => state.template);
  const [hasActualAgreement, setHasActualAgreement] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const accessToken = useSelector(accessTokenSelector)!;
  const eventID = event?.event_id;

  useEffect(() => {
    const handleGetEvent = async () => {
      if (!eventID) return;
      try {
        const { data: event } = await api.getEvent(accessToken, eventID);
        const orgId = event.organization?.id;
        if (!orgId) return;
        const { data: org } = await api.getOrganization(accessToken, orgId);
        const hasAgreement =
          !!org.has_actual_agreement && !!org.has_approved_legal_entity;
        setHasActualAgreement(hasAgreement);
        if (!hasAgreement) setShowModal(true);
      } catch {
        // ignore
      }
    };
    handleGetEvent();
  }, [accessToken, eventID]);

  return (
    <AppBar color="inherit" elevation={0} position="static" {...restProps}>
      <Toolbar variant="dense">
        <IconButton color="inherit" component={Link} to={backPath} edge="start">
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">{template.title}</Typography>
        <Box mx={2}>
          <IconButton color="inherit" onClick={onEdit} size="small">
            <Settings />
          </IconButton>
          <IconButton color="inherit" onClick={onDelete} size="small">
            <Delete color="error" />
          </IconButton>
        </Box>
        {loading && (
          <CircularProgress
            color="secondary"
            size={24}
            variant="indeterminate"
          />
        )}
        <Box flex={1} />
        <Box clone mr={1}>
          <Button
            disabled={loading}
            onClick={onSave}
            color="primary"
            startIcon={<Save />}
          >
            Сохранить шаблон
          </Button>
        </Box>
        <Box clone mr={1}>
          <Button
            color="secondary"
            onClick={onDiplomas}
            disabled={!hasActualAgreement}
          >
            Создать дипломы
          </Button>
        </Box>
        <Box clone mr={1}>
          <Button
            component={Link}
            to={compile(Routes.TEMPLATE_DIPLOMAS)({ templateId: template.id })}
            variant="outlined"
            endIcon={<ArrowForward />}
          >
            Дипломы
          </Button>
        </Box>
      </Toolbar>
      <ConfirmationDialog
        onClose={() => {
          setShowModal(false);
        }}
        disableBackdropClick
        disableEscapeKeyDown
        title="Отсутствует актуальное соглашение"
        text={
          <>
            <p>
              Для работы с платформой Талант требуется актуальное соглашение.
            </p>
            <p>
              Пожалуйста, заключите или обновите соглашение об информационном
              взаимодействии с Ассоциаций участников технологических кружков
              (оператором платформы Талант).
            </p>
            <p>
              Это можно сделать с помощью{' '}
              <a
                href={'https://talent.kruzhok.org/org'}
                target="_blank"
                rel="noopener noreferrer"
                className="appLink"
              >
                Кабинета организатора
              </a>
            </p>
          </>
        }
        cancelText="Закрыть"
        open={showModal}
        noSubmit
      />
    </AppBar>
  );
}
